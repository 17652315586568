@import "../../../data/styles.css";

.card {
	display: flex;
	mix-blend-mode: normal;
	border-radius: 20px;
	outline: 5px double #d6d6d6;
	width: 95%;
	height: 95%;
	overflow: hidden;
  	box-sizing: border-box;
}

.card-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 30px;
	padding-bottom: 5px;
}

.card-header {
	display: flex;
	align-items: top;
	justify-content: left;
}

.card-icon {
	font-size: 15px;
	color: var(--tertiary-color);
}

.card-title {
	color: var(--secondary-color);
	font-size: 14px;
	padding-left: 20px;
	font-weight: 650;
}

.card-body {
	padding-top: 40px;
	font-size: 15px;
}
