@import "../../../data/styles.css";

.works-body {
	margin-bottom: 10px;
	flex-direction: column;
}

.work {
	display: flex;
	justify-content: left;
	flex-direction: row;
	padding-bottom: 40px;
	width: 100%;
}

.works {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.work-image {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	outline: 2px solid white;
	box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
}

.work-description{
	flex-direction: column;
	padding-left: 10px;
	box-sizing: border-box;
	width: 100%;
}

.work-title {
	position: flex;
  	word-wrap: break-word; 
	font-size: 15px;
	font-weight: 700;
	color: var(--secondary-color);
}

.work-subtitle {
	position: flex;
	font-size: 12px;
	color: var(--secondary-color);
}

.work-duration {
	position: flex;
	font-size: 12px;
	text-align: right;
	color: var(--tertiary-color);
}
